import React from 'react';
import './App.css';
import TepetitlanHomepage from './homePage'; // Asegúrate de que la ruta sea correcta

function App() {
  return (
      <div className="App">
        <TepetitlanHomepage />
      </div>
  );
}

export default App;