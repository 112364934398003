import React, { useEffect, useState } from 'react';
import { Facebook, ArrowLeft, ArrowRight } from 'lucide-react';
import logo from "./img/WhatsApp_Image_2024-09-19_at_3.53.49_PM-removebg-preview-_4_.webp";
import convocatoria from './img/CONVOCATORIA PUBLICA PARA SELECCIONAR AL TITULAR DEL ORGANO DE CONTROL INTERNO (2).png';
import video from './img/Snapsave.app_AQPBa1J0ugU7G7udB2k2sFoQfw5qG57cc1ZDyTvbx5b9svXMmXeXqNkiCJRXzoZYpwNGjaHZDIezVi3pfyUTgB6h.mp4';
import gob from './img/gobierno_mexico_.jpg';
import estado from "./img/captura_de_pantalla_2023-06-22_a_las_10.40.33_0.png";
import dif from "./img/images (6).jpeg";
import homepa from "./img/WhatsApp-Image-2023-10-19-at-10.25.41-AM-1024x461.jpeg";
import homepa2 from "./img/FB_IMG_1728452362829.jpg";
import homepa3 from "./img/FB_IMG_1728452437352.jpg";



const Header = () => (
    <header className="bg-white p-3 flex flex-wrap justify-between items-center">
        <img src={logo} alt="Gobierno Municipal de Tepetitlan 2024-2027" className="w-24" />
        <nav>
            <ul className="flex flex-wrap">
                {["INICIO", "GOBIERNO", "MUNICIPIO", "TRANSPARENCIA", "SISTEMA DIF", "SERVICIOS Y TRÁMITES", "OTROS"].map((item, index) => (
                    <li key={index} className="mr-2 cursor-pointer">
                        <button
                            className="text-black no-underline font-poppins font-light px-3 py-2 transition-colors duration-300 ease-in-out
                                       hover:bg-[#e17027] hover:text-white rounded-lg"
                            onClick={() => window.location.href = "http://www.tepetitlanhgo.gob.mx/"}
                        >
                            {item}
                        </button>
                    </li>
                ))}
            </ul>
        </nav>
        <input type="text" placeholder="Buscar" className="p-1" aria-label="Buscar" />
    </header>
);

const slides = [
    {
        id: 1,
        title: "TEPETITLÁN",
        subtitle: "Un nuevo comienzo",
        description: "En este nuevo comienzo, renovamos nuestra dedicación hacia el progreso de Tepetitlán. Juntos, avanzamos con proyectos y acciones que transformarán nuestro municipio.",
        image:homepa
    },
    {
        id: 2,
        title: "DEDICACION",
        subtitle: "Al servicio de un futuro mejor",
        description: "Trabajamos con dedicación para mejorar la calidad de vida de todos los ciudadanos de Tepetitlán.",
        image:homepa3
    },
    {
        id: 3,
        title: "COMPROMISO",
        subtitle: "Por y para nuestra comunidad",
        description: "Estamos comprometidos con ustedes, ofreciendo una amplia gama de soluciones diseñadas para satisfacer las necesidades de nuestra comunidad.",
        image:homepa2
    }
];

const HeroSlider = () => {
    const [currentSlide, setCurrentSlide] = useState(0);

    const nextSlide = () => {
        setCurrentSlide((prev) => (prev + 1) % slides.length);
    };

    const prevSlide = () => {
        setCurrentSlide((prev) => (prev - 1 + slides.length) % slides.length);
    };

    useEffect(() => {
        const interval = setInterval(nextSlide, 5000);
        return () => clearInterval(interval);
    }, []);

    return (
        <div className="relative w-full h-screen overflow-hidden bg-black">
            {slides.map((slide, index) => (
                <div
                    key={slide.id}
                    className={`absolute inset-0 w-full h-full transition-opacity duration-1000 ease-in-out ${
                        index === currentSlide ? 'opacity-100' : 'opacity-0'
                    }`}
                >
                    <img
                        src={slide.image}
                        alt={slide.title}
                        className="absolute inset-0 w-full h-full object-cover"
                    />
                    <div

                        className="absolute inset-0 bg-black bg-opacity-70 flex flex-col justify-center lg:px-32 md:px-16 sm:px-12 ">
                        <div className="max-w-2xl">
                            <h2 className="text-white text-5xl md:text-6xl sm:text-[4rem] font-bold mb-2 font-oswald text-left">{slide.title}</h2>
                            <h3 className="text-white text-4xl md:text-4xl sm:text-[3rem] mb-4 font-oswald text-left">{slide.subtitle}</h3>
                            <p className="text-white text-xl md:text-xl sm:text-[2rem] text-left">{slide.description}</p>
                        </div>
                    </div>


                </div>
            ))}
            <button
                onClick={prevSlide}
                className="absolute left-4 top-1/2 transform -translate-y-1/2 bg-white bg-opacity-50 p-2 rounded-full"
            >
                <ArrowLeft className="text-black"/>
            </button>
            <button
                onClick={nextSlide}
                className="absolute right-4 top-1/2 transform -translate-y-1/2 bg-white bg-opacity-50 p-2 rounded-full"
            >
                <ArrowRight className="text-black" />
            </button>
            <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 flex space-x-2">
                {slides.map((_, index) => (
                    <button
                        key={index}
                        onClick={() => setCurrentSlide(index)}
                        className={`w-3 h-3 rounded-full ${
                            index === currentSlide ? 'bg-white' : 'bg-white bg-opacity-50'
                        }`}
                    />
                ))}
            </div>
        </div>
    );
};

const MainContent = () => (
    <div className="bg-red-100 p-2 text-center mt-0"> {/* mt-0 para eliminar el margen superior */}
        <h1 className="text-2xl text-red-900 my-5">SITIO EN MANTENIMIENTO</h1>
    </div>
);


const VideoContent = () => (
    <div className="text-center mt-5 max-w-full">
        <video width="600" autoPlay muted loop controls className="mx-auto">
            <source src={video} type="video/mp4"/>
            Tu navegador no soporta la reproducción de video.
        </video>
    </div>
);

const Convocatoria = () => (
    <div className="bg-gray-100 border border-gray-300 rounded-lg p-5 my-5 mx-auto max-w-4xl shadow-md text-center">
        <div className="relative inline-block mb-5">
            <h3 className="bg-[#53172b] text-white p-2 rounded-lg absolute -top-6 left-1/2 transform -translate-x-1/2">CONVOCATORIA</h3>
            <img src={convocatoria} alt="CONVOCATORIA PUBLICA PARA SELECCIONAR AL TITULAR DEL ORGANO DE CONTROL INTERNO" className="max-w-[60%] h-auto mx-auto mt-12" />
        </div>
        <p className="text-lg text-black">Para mayores informes y bases completas de la convocatoria, consulte:</p>
        <a
            href="https://drive.google.com/file/d/1IM8mzrQ6jx5PWEz3GczOHEsYASma861x/view?usp=sharing"
            className="bg-[#53172b] text-white py-2 px-4 rounded-lg text-lg cursor-pointer inline-block mt-2 hover:bg-[#731c3a]"
            rel="noopener noreferrer"
            target="_blank"
        >
            ¡AQUÍ!
        </a>
    </div>
);

const SitesOfInterest = () => (
    <div className="bg-white p-5 mt-5 rounded-lg text-center">
        <h3 className="text-black mb-5">SITIOS DE INTERÉS</h3>
        <div className="flex justify-center items-center flex-wrap">
            {[
                { href: "https://www.gob.mx/", src: gob, alt: "Gobierno de México" },
                { href: "https://www.hidalgo.gob.mx/", src: estado, alt: "Gobierno del estado" },
                { href: "https://dif.hidalgo.gob.mx/", src: dif, alt: "DIF" }
            ].map((site, index) => (
                <a
                    key={index}
                    href={site.href}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="mx-5"
                    aria-label={site.alt}
                >
                    <img src={site.src} alt={site.alt} className="h-24 hover:scale-110 transition-transform duration-300" />
                </a>
            ))}
        </div>
    </div>
);

const Footer = () => (
    <footer className="bg-gray-100 p-5">
        <div className="flex flex-wrap justify-between items-start max-w-6xl mx-auto">
            <div className="w-full md:w-1/2 mb-5 md:mb-0 text-center md:text-left">
                <img
                    src={logo}
                    alt="Logo Ayuntamiento"
                    className="w-20 h-auto mb-2 mx-auto md:mx-0"
                />
                <p className="italic text-gray-700">"En este nuevo comienzo, reafirmamos nuestro compromiso con Tepetitlán."</p>
            </div>
            <div className="w-full md:w-1/2 text-center md:text-right">
                <p>PLAZA DE LA CONSTITUCION S/N, COLONIA CENTRO</p>
                <p>TEPETITLÁN, HGO. C.P. 42920</p>
                <div className="mt-2">
                    <a
                        href="https://www.facebook.com/profile.php?id=61565128448732"
                        target="_blank"
                        rel="noopener noreferrer"
                        aria-label="Facebook"
                    >
                        <Facebook className="inline-block text-gray-700 hover:text-blue-600" size={24} />
                    </a>
                </div>
            </div>
        </div>
        <p className="text-center text-sm text-gray-600 mt-5">© 2024 Ayuntamiento del Municipio de Tepetitlán, Hidalgo. Derechos Reservados</p>
    </footer>
);

const TepetitlanHomepage = () => (
    <div className="font-sans">
        <Header />
        <HeroSlider />
        <MainContent />
        <Convocatoria />
        <VideoContent />
        <SitesOfInterest />
        <Footer />
    </div>
);

export default TepetitlanHomepage;
